<template>
  <div id="app">
    <nav-bar />
    <main class="conteudo">
      <transition name="nav-fade">
        <router-view />
      </transition>
    </main>
    <aside id="faleconosco" class="faleconosco">
      <article class="container-base">
        <h1>Fale Conosco</h1>
        <fale-conosco />
      </article>
    </aside>
    <Footer />
    <cookie-banner />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import FaleConosco from '@/components/FaleConosco.vue'
import CookieBanner from '@/components/CookieBanner.vue'

export default {
  components: {
    NavBar,
    Footer,
    FaleConosco,
    CookieBanner,
  },
  metaInfo() {
    const description = 'A AHBB | Rede Santa Casa carrega consigo uma história de importantes avanços na gestão de saúde, preparada para um presente e um futuro em que os desafios são muitos, mas a vontade e a capacidade de vencê-los é ainda mais vasta.'
    return {
      title: null,
      titleTemplate: s => `${s ? `${s} -` : ''} AHBB | Rede Santa Casa`,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: description,
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          vmid: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          vmid: 'og:image',
          content: new URL(require('@/assets/bg_hero.jpg'), window.location).href,
        },
        {
          property: 'twitter:image',
          vmid: 'twitter:image',
          content: new URL(require('@/assets/bg_hero.jpg'), window.location).href,
        },
        {
          name: 'facebook-domain-verification',
          content: '5lda4u50hekxfjo9zq5h1lhv8etzqf',
        },
      ],
    }
  },
}
</script>

<style lang="scss">

* {
  box-sizing: border-box;

  $ahbb-blue: #31425F;
  $ahbb-green: #83B76A;

  --ahbb-blue: #{$ahbb-blue};
  --ahbb-green: #{$ahbb-green};

  --ahbb-green-light-1: #{lighten($ahbb-green, 10)};
  --ahbb-green-light-2: #{lighten($ahbb-green, 22.5)};
  --ahbb-green-light-3: #{lighten($ahbb-green, 35)};

  --ahbb-blue-gradient: linear-gradient(70deg, #131B29 0%, #293b59 60%)
}

body {
  margin: 0;
  padding: 0;
}

html, body, #app {
  height: 100%;

  scroll-behavior: smooth;
}

#app {
  display: flex;
  flex-direction: column;

  font-family: Montserrat, 'DejaVu Sans', Verdana, sans‑serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;

  color: var(--ahbb-blue);

  nav {
    flex: 0 0 auto;
  }

  footer {
    flex: 0 0 auto;
  }

  .conteudo {
    flex: 1 0 auto;
    margin: 0;
  }
}

h1 {
  color: var(--ahbb-green);
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 3px;
}

h2 {
  font-size: 35px;
  font-weight: normal;
}

h3 {
  color: var(--ahbb-green);
  font-size: 25px;
  font-weight: bold;
}

p {
  font-size: 17px;
  color: var(--ahbb-blue);
  text-transform: none;
  font-weight: 500;
  letter-spacing: normal;
}

img {
  transition: opacity .2s ease-in-out;

  &[lazy=loading] {
    opacity: 0;
  }
}

button, .button {
  display: inline-block;
  padding: 12px 28px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50vh;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;

  &.verde {
    border-color: var(--ahbb-green);
    color: var(--ahbb-green);

    &:hover:not(:disabled) {
      color: white;
      background-color: var(--ahbb-green);
    }
  }

  transition: color .25s, background-color .25s;

  &:hover:not(:disabled) {
    cursor: pointer;
    color: var(--ahbb-green);
    background-color: white;
  }

  &:disabled {
    background: lightgray;
  }
}

.box-destaque {
  background: var(--ahbb-blue-gradient);
  padding: 60px;
  text-align: center;
  color: white;
  margin: 64px 0;

  p {
    color: white;
    font-size: 20px;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.hero {
    padding: 96px 10%;
    text-align: center;

    background-size: cover;
    background-position: center;
    background-color: #293b59b2;
    background-blend-mode: multiply;

    h1 {
      margin-bottom: 48px;
    }

    p.subh {
      color: white;
      font-size: 32px;
      font-weight: 300;
      margin-top: 8px;
      margin-bottom: 32px;
    }

    p {
      color: white;
      font-size: 17px;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    h1 {
      animation: hero-fade-in 1.5s 0s cubic-bezier(.23,1,.32,1) both;
    }

    p.subh {
      animation: hero-fade-in 1.5s 0.5s cubic-bezier(.23,1,.32,1) both;
    }

    @keyframes hero-fade-in {
      0% {
        opacity: 0;
        transform: translateY(15px) scaleY(.98);
      }

      90% {
        transform: none;
      }
    }
  }

.container-base {
  padding: 0 5%;
  padding: 0 unquote('min(120px, 5%)');
  margin: 0 auto;
  max-width: 1600px;
}

.nav-fade-enter-active{
  transition: opacity .15s .15s ease-out;
}

.nav-fade-enter {
  opacity: 0;
}

.nav-fade-leave-active {
  transition: opacity .15s ease-in;
}

.nav-fade-leave-to {
  opacity: 0;
}

#faleconosco {
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 16px;
  background-color: #293b590b;

  h1 {
    text-align: center;
  }
}

  @keyframes pulse {
    40% {
      opacity: .6;
    }
    100% {
      opacity: .45;
    }
  }

</style>
