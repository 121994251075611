import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import VueGtm from 'vue-gtm'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(VueLazyload, {
  observer: true,
  observerOptions: {
    rootMargin: '80px 10px',
    threshold: 0,
  },
})

// Google tag manager
Vue.use(VueGtm, {
  id: 'GTM-PXHJ3BH',
  debug: false,
  vueRouter: router,
  enabled: JSON.parse(window.localStorage.getItem('cookies-ok')),
})

Vue.use(VueMeta)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
