<template>
  <div v-if="mostrar" class="cookie-banner">
    <div class="container-base cookie-container">
      <p>Este site utiliza cookies para melhorar a sua experiência e de outros usuários. Nenhum dado pessoal é coletado por nós.</p>
      <button class="button concordo verde" @click="onClickConcordo">
        Concordo
      </button>
      <button class="fechar verde" @click="mostrar = false">
        &#x2716;
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .cookie-banner {
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;

    background: #f6f6f8;

    .cookie-container {
      display: grid;
      grid-template: "a b" / 1fr auto auto;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      @media (max-width: 540px) {
        grid-template: "a" 1fr "b" auto;
      }
    }

    button.fechar {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px;

      color: var(--ahbb-blue);
      border: none;

      &:hover {
        background: none;
        color: var(--ahbb-green);
      }
    }
  }
</style>

<script>

export default {
  data: () => ({
    mostrar: true,
  }),
  methods: {
    onClickConcordo() {
      window.localStorage.setItem('cookies-ok', true)
      this.mostrar = false
      this.$gtm?.enable(true)
    },
  },
  created() {
    if (window.localStorage.getItem('cookies-ok'))
      this.mostrar = false
  },
}
</script>
