<template>
  <div class="nav-root">
    <div class="nav-bar" :class="{pop, open}">
      <div class="nav-container container-base">
        <router-link to="/" class="logo-topo">
          <img
            src="@/assets/logo_ahbb_azul.svg"
            height="42"
          >
        </router-link>
        <nav class="nav-links">
          <router-link
            class="router-link"
            v-for="(link, i) of links"
            :to="link.to"
            :key="i"
            :style="{'animation-delay': 0.2 + 0.1 * i + 's'}"
            :class="{'router-link-no-highlight': link.noHighlight}"
          >
            {{ link.value }}
          </router-link>
          <a 
            class="router-link" 
            target="_blank" 
            :style="{'animation-delay': 0.2 + 0.1 * 7 + 's'}"
            href="https://www.editais.ahbb.org.br/"
          >
            EDITAIS
          </a>
        </nav>
        <button class="hamburger-button" @click="open = !open">
          ☰
        </button>
      </div>
    </div>
    <div class="nav-dummy" />
    <div class="hamburger-menu-wrapper">
      <transition name="fade">
        <div class="hamburger-menu-backdrop" v-if="open" :class="{open}" @click="open = false" />
      </transition>
      <nav class="hamburger-menu" :class="{open}">
        <router-link
          class="router-link"
          v-for="(link, i) of links"
          :to="link.to"
          :key="i"
          :style="{'animation-delay': 0.1 + 0.08 * i + 's'}"
          :class="{'router-link-no-highlight': link.noHighlight}"
        >
          {{ link.value }}
        </router-link>
        <a 
          class="router-link" 
          target="_blank" 
          :style="{'animation-delay': 0.2 + 0.1 * 7 + 's'}"
          href="https://www.editais.ahbb.org.br/"
        >
          EDITAIS
        </a>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .nav-root {
    z-index: 99;
  }

  .nav-bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    padding-top: 22px;
    padding-bottom: 22px;

    background: white;
    box-shadow: 0px -6px 9px #0000;

    transition: box-shadow .2s;

    .nav-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .logo-topo {
      animation: fade-down .3s 0.05s ease-out both;

      img {
        height: 42px;
        transition: height .2s;

        @media screen and (max-width: 1440px) {
          height: 38px;
        }
      }

      align-self: flex-end;
    }

    &.pop {
      box-shadow: 0px -6px 9px #000;

      .logo-topo img {
        height: 32px;
      }
    }

    .nav-links {
      display: flex;
      flex-direction: row;

      a, .router-link {
        color: #636363;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;

        margin: 4px 16px;
        align-self: flex-end;

        transition: color .25s;

        &:hover {
          color: var(--ahbb-green);
        }

        &.router-link-active:not(.router-link-no-highlight) {
          color: var(--ahbb-green);
        }

        animation: fade-down .3s ease-out both;
      }
    }

    .hamburger-button {
      display: none;
      padding: 0;
      color: var(--ahbb-blue);
      font-size: 28px;
      line-height: 0;

      transition: color .2s ease;

      &:hover {
        color: var(--ahbb-green);
      }
    }
  }

  .nav-dummy {
    height: 90px;

    transition: height .2s;

    @media screen and (max-width: 1440px) {
      height: 86px;
    }
  }

  .hamburger-menu {
    display: none;

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9;

    padding-left: 8px;
    padding-right: 8px;
    padding-top: 108px;
    width: 260px;
    max-width: 100%;

    flex-direction: column;
    align-items: stretch;

    background: white;
    box-shadow: 0px -6px 9px #000;

    transition: transform .15s ease-in;
    transform: translateX(110%); //fallback
    transform: translateX(calc(100% + 20px));

    &.open {
      z-index: 9;
      transition: transform .25s ease-out;
      transform: none;
    }

    a, .router-link {
      color: #636363;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 16px;

      padding: 12px 16px;
      text-align: end;

      &:hover {
        color: var(--ahbb-green);
      }

      &.router-link-active:not(.router-link-no-highlight) {
        color: var(--ahbb-green);
      }

      animation: fade-left .3s ease-out both;
    }

    @media screen and (max-width: 480px) {
      width: 100%;

      a, .router-link {
        text-align: center;
      }
    }
  }

  .hamburger-menu-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #0006;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s ease-out;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  @media screen and (max-width: 1290px) {
    .nav-bar {
      &.open {
        box-shadow: 0px -6px 9px #000;
      }

      .nav-links {
        display: none;
      }

      .hamburger-button {
        display: block;
      }
    }
    .hamburger-menu {
      display: flex;

      .router-link {
        display: none;
      }

      &.open {
        .router-link {
          display: unset;
        }
      }
    }
    .hamburger-menu-backdrop {
      display: unset;
    }
  }

  @keyframes fade-down {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
  }

  @keyframes fade-left {
    0% {
      transform: translateX(15px);
      opacity: 0;
    }
  }
</style>

<script>
export default {
  data: () => ({
    links: [
      {
        value: 'Quem Somos',
        to: '/quem-somos',
      },
      {
        value: 'Onde Estamos',
        to: '/onde-estamos',
      },
      {
        value: 'Parceiros',
        to: '/#parceiros',
      },
      {
        value: 'COVID-19',
        to: '/covid-19',
      },
      {
        value: 'Transparência',
        to: '/transparencia',
      },
      {
        value: 'Compliance',
        to: '/compliance',
      },
      {
        value: 'Fale Conosco',
        to: '#faleconosco',
        noHighlight: true,
      },
    ],
    open: false,
    wScrollY: 0,
  }),
  computed: {
    pop() {
      return this.wScrollY > 28
    },
  },
  methods: {
    onScroll(e) {
      this.wScrollY = window.scrollY
    },
  },
  watch: {
    '$route'(from, to) {
      this.open = false
    },
  },
  created() {
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
}
</script>
