<template>
  <footer>
    <!-- A classe ".container-base" está em "App.vue". -->
    <div class="container-base">
      <div class="footer-container">
        <div class="logo">
          <img src="@/assets/logo_ahbb_branco.svg">
        </div>
        <div class="paginas">
          <router-link to="/quem-somos">
            Quem Somos
          </router-link>
          <router-link to="/onde-estamos">
            Onde Estamos
          </router-link>
          <router-link to="/covid-19">
            Covid-19
          </router-link>
          <router-link to="/transparencia">
            Transparência
          </router-link>
          <router-link to="/compliance">
            Compliance
          </router-link>
          <a
            target="_blank"
            href="https://www.editais.ahbb.org.br/"
          >
            Editais
          </a>
        </div>
        <div class="social">
          <a target="_blank" href="https://www.facebook.com/ahbb.org/">
            <img src="@/assets/ic_facebook.svg">
          </a>
          <a target="_blank" href="https://instagram.com/redesantacasa">
            <img src="@/assets/ic_instagram.svg">
          </a>
          <a target="_blank" href="https://br.linkedin.com/company/ahbb">
            <img src="@/assets/ic_linkedin.svg">
          </a>
        </div>
        <div class="certificados">
          <b>Certificações e premiações:</b>
          <div class="selos">
            <img src="@/assets/ic_cebas.png">
            <div class="topo">
              <img src="@/assets/ic_amib.png">
              <div class="base">
                <img src="@/assets/ic_uti_ouro2.png">
                <img src="@/assets/ic_uti_prata.png">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copy">
        <p>© AHBB|Rede Santa Casa 2022, todos os direitos reservados</p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  background: var(--ahbb-blue-gradient);

  p {
    color: white;
    font-weight: 100;
    font-size: 12px;
  }
}

.copy {
  text-align: center;

  /* Colocamos 29px para completarmos
   * 32px de margem inferior.
   */
  margin-bottom: 29px;

  @media (max-width: 740px) {
    margin-top: -8px;
  }
}

.container-base {
  padding: 0 40px 0 120px;

  @media (max-width: 1200px) {
    padding: 0 90px;
  }

  @media (max-width: 768px) {
    padding: 0 36px;
  }

  @media (max-width: 425px) {
    padding: 0 36px;
  }

  .footer-container {
    display: grid;
    grid-template-areas:
      "logo certificados"
      "paginas certificados"
      "social certificados";
    padding-top: 32px;
    padding-bottom: 48px;
    grid-auto-flow: dense;

    color: white;

    .logo {
      grid-area: logo;
    }

    .paginas {
      grid-area: paginas;
      display: flex;
      flex-flow: row wrap;
      padding-top: 28px;

      a {
        color: white;
        margin-right: 20px;
        margin-bottom: 10px;
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    .certificados {
      grid-area: certificados;

      .selos {
        display: flex;

        // Ícone Cebas.
        img:first-child {
          grid-area: cebas;
          height: 105px;
          margin-right: 15px;
        }

        img {
          margin-top: 10px;
          margin-right: 15px;
        }

        .topo {
          display: flex;
          flex-direction: column;

          img {
            height: 40px;
            width: 40px;
          }

          .base img {
            height: 50px;
            width: 40px;
          }
        }
      }
    }

    .social {
      grid-area: social;
      margin: 38px 0 48px 0;

      a {
        margin: 8px 12px 0px 0px;

        img {
          width: 28px;
          height: 28px;
        }
      }
    }

    @media (max-width: 1200px) {
      column-gap: 50px;

      .paginas {
        a {
          margin-right: 30px;
        }
      }
    }

    @media (max-width: 740px) {
      grid-template-areas:
        "logo"
        "paginas"
        "social"
        "certificados";

      .logo {
        justify-self: start;
      }

      .paginas {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 0.9rem;
      }
    }

    @media (max-width: 410px) {
      padding-top: 32px;

      grid-template-areas:
        "logo"
        "paginas"
        "social"
        "certificados";
    }
  }

  .copy {
    display: flex;
    justify-content: center;
   
    @media (max-width: 740px) {
      p {
        width: 190px;
      }
    }
  }
}
</style>

<script>
export default {
  data: () => ({
    emailEnviado: false,
  }),
}
</script>
