const DirectusSDK = require('@directus/sdk-js').default
const cms = new DirectusSDK({
  url: 'https://cms.ahbb.org.br/',
  project: 'ahbb-site',
})

module.exports = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {
      breadcrumb: [
        { name: 'Home' },
      ],
    },
  },
  {
    path: '/quem-somos',
    name: 'QuemSomos',
    component: () => import(/* webpackChunkName: "quemsom" */ '@/views/QuemSomos.vue'),
    meta: {
      breadcrumb: [
        { name: 'Home', link: '/' },
        { name: 'QuemSomos' },
      ],
    },
  },
  {
    path: '/onde-estamos',
    name: 'OndeEstamos',
    component: () => import(/* webpackChunkName: "ondeest" */ '@/views/OndeEstamos.vue'),
    meta: {
      breadcrumb: [
        { name: 'Home', link: '/' },
        { name: 'OndeEstamos' },
      ],
    },
  },
  {
    path: '/onde-estamos/:id',
    name: 'OndeEstamosDetalhe',
    component: () => import(/* webpackChunkName: "ondeestdet" */ '@/views/OndeEstamosDetalhe.vue'),
    props: true,
    meta: {
      sitemap: {
        slugs: async() => (await cms.getItems('onde_estamos', { fields: 'id' })).data,
      },
      breadcrumb: [
        { name: 'Home', link: '/' },
        { name: 'OndeEstamos', link: '/onde-estamos' },
        { name: 'OndeEstamosDetalhe' },
      ],
    },
  },
  {
    path: '/transparencia/',
    name: 'Transparencia',
    component: () => import(/* webpackChunkName: "transp" */ '@/views/Transparencia.vue'),
    meta: {
      breadcrumb: [
        { name: 'Home', link: '/' },
        { name: 'Transparencia' },
      ],
    },
  },
  {
    path: '/transparencia/institucional',
    name: 'TransparenciaInstitucional',
    component: () => import(/* webpackChunkName: "transpinst" */ '@/views/TransparenciaInstitucional.vue'),
    meta: {
      breadcrumb: [
        { name: 'Home', link: '/' },
        { name: 'Transparencia', link: '/transparencia/' },
        { name: 'TransparenciaInstitucional' },
      ],
    },
  },
  {
    path: '/transparencia/projetos',
    name: 'TransparenciaProjetos',
    component: () => import(/* webpackChunkName: "transpproj" */ '@/views/TransparenciaProjetos.vue'),
    meta: {
      breadcrumb: [
        { name: 'Home', link: '/' },
        { name: 'Transparencia', link: '/transparencia/' },
        { name: 'TransparenciaProjetos' },
      ],
    },
  },
  {
    path: '/transparencia/covid-19',
    name: 'TransparenciaCovid-19',
    component: () => import(/* webpackChunkName: "transpproj" */ '@/views/TransparenciaCovid-19.vue'),
    meta: {
      breadcrumb: [
        { name: 'Home', link: '/' },
        { name: 'Transparencia', link: '/transparencia/' },
        { name: 'TransparenciaCovid-19' },
      ],
    },
  },
  {
    path: '/compliance/',
    name: 'Compliance',
    component: () => import(/* webpackChunkName: "compliance" */ '@/views/Compliance.vue'),
  },
  {
    path: '/covid-19/',
    name: 'Covid-19',
    component: () => import(/* webpackChunkName: "covid" */ '@/views/Covid-19.vue'),
    meta: {
      breadcrumb: [
        { name: 'Home', link: '/' },
        { name: 'Covid-19' },
      ],
    },
  },
]
