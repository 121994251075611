import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) =>
      setTimeout(() =>
        resolve(savedPosition ?? (to.hash ? { selector: to.hash, offset: { y: 100 } } : { x: 0, y: 0 })),
      200,
      ),
    )
  },
  afterEach(to, from, next) {
    window.fbq('track', 'PageView')
  },
})
export default router
