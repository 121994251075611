<template>
  <div class="fale-conosco-root">
    <div class="info-contato">
      <h2>Para currículos:</h2>
      <div class="copy" @click="copyTextCurriculo" @mouseout="setCopyMessage" :aria-label="this.textCopy">
        <p>
          <input type="text" value="currículos@ahbb.org.br" class="offDisplay" id="emailCurriculo"> currículos@ahbb.org.br
        </p>
        <div class="btn" id="btn">
          <img src="../assets/copy.png" alt="Copiar" class="btnImg">
        </div>
      </div>

      <h2>Para orçamentos:</h2>
      <div class="copy" @click="copyTextParcerias" @mouseout="setCopyMessage" :aria-label="this.textCopy">
        <p>
          <input type="text" value="parcerias@ahbb.org.br" class="offDisplay" id="emailParcerias"> parcerias@ahbb.org.br
        </p>
        <div class="btn">
          <img src="../assets/copy.png" alt="Copiar" class="btnImg">
        </div>
      </div>
    </div>

    <form v-if="!formEnviado" @submit.prevent="submit" action="">
      <h2>Envie-nos uma mensagem</h2>
      <select class="select" name="cidade" v-model="form.cidade" required @mouseover="created">
        <option
          class="optionClass"
          v-for="(optionCidade, i) in optionsCidade"
          :value="optionCidade.value"
          :key="i"
        >
          {{ optionCidade.text }}
        </option>
      </select>
      <select class="select" name="assunto" v-model="form.assunto" required>
        <option
          class="optionClass"
          v-for="(optionAssunto, i) in optionsAssunto"
          :value="optionAssunto.value"
          :key="i"
        >
          {{ optionAssunto.text }}
        </option>
      </select>
      <input type="text" placeholder="Nome" name="nome" v-model="form.nome" required>
      <input type="email" placeholder="Email" name="email" v-model="form.email" required>
      <textarea rows="4" placeholder="Mensagem" name="mensagem" v-model="form.mensagem" required />
      <button type="submit" class="button verde" :disabled="formEnviando">
        Enviar
      </button>
      <div class="form-invalido" v-if="formInvalido">
        Por favor, preencha corretamente todos os campos
      </div>
      <div class="form-erro" v-if="formErro">
        Ocorreu um erro ao enviar o formulário. Tente novamente.
      </div>
    </form>
    <div v-else>
      <h2>Envie-nos uma mensagem</h2>
      <div>Prezado(a) {{ form.nome }}, sua mensagem foi enviada. Agradecemos o contato!</div>
      <br>
      <button class="button verde" @click="resetForm">
        Nova mensagem
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .fale-conosco-root {
    display: grid;
    padding-bottom: 16px;

    grid-template-columns: 1fr 2fr;

    @media (max-width: 680px) {
      grid-template-columns: 1fr;

      .btn {
        display: none;
      }
    }
  }

  h2 {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 3px;
    margin-top: 28px;
  }

  .copy {
    display: flex;
    width: 240px;
      p {
        margin: 5px 0 0 0;
        text-align: center;
        font-weight: normal;
      }
  }
  
  .copy:hover {
    cursor: pointer;
  }

  .offDisplay {
    display: none;
  }

  .btn {
    background-color: #D9D9D9;
    border-radius: 16px;
    padding: 8px;
    width: 32px;
    height: 32px;
    margin-left: 5px;
  }

  [aria-label] {
    position: relative;
  }

  [aria-label]:after {
    font-size: 15px;
    display: none;
    position: absolute;
    padding: 2px 4px;
    left: calc(155px);
    top: calc(-30px);
    content: attr(aria-label);
    white-space: nowrap;
    background-color: #D9D9D9;
    color: #2e3856;
  }

  [aria-label]:hover::after {
    display: block;
  }

  .btnImg {
    width: 16px;
    height: 15px;
    color: #245074;
  }

  form {
    overflow: hidden;

    .select {
      margin-top: 24px;
      -webkit-appearance: none;
    }

    input, textarea, select{
      display: block;
      width: 100%;
      max-width: 100%;

      border: none;
      border-bottom: 1px solid #a0a0a0;

      background: transparent;

      font-family: Montserrat, Helvetica, sans-serif;
      color: var(--ahbb-blue);
      font-size: 14px;

      &::placeholder {
        font-family: Montserrat, Helvetica, sans-serif;
        color: var(--ahbb-blue);
        opacity: .75;
        font-size: 13px;
        font-style: italic;
        transition: opacity .2s ease;
      }

      &:focus {
        &::placeholder {
          opacity: .5;
        }
      }

    }

    input {
      margin: 24px 0;
    }

    textarea {
      margin: 36px 0;
    }

    .erro-form {
      margin: 8px;
    }
  }
</style>

<script>
import cms from '@/lib/cms.js'

export default {
  data: () => ({
    textCopy: 'Clique para copiar',
    optionsAssunto: [
      { text: 'Assunto', value: 'assunto' },
      { text: 'Comentários e Sugestões', value: 'comentariosSugestoes' },
      { text: 'Dúvidas', value: 'duvidas' },
      { text: 'Reclamações', value: 'reclamacoes' },
      { text: 'Elogios', value: 'elogios' },
    ],
    cont: 0,
    optionsCidade: [
      { text: 'Cidade', value: 'Cidade' },
    ],
    arrayCidades: [],
    cms,
    clipboardText: null,
    formEnviado: false,
    formEnviando: false,
    formInvalido: false,
    formErro: false,
    form: {
      cidade: 'Cidade',
      assunto: 'assunto',
      nome: '',
      email: '',
      mensagem: '',
    },
  }),
  methods: {
    async created() {
      this.arrayCidades = (await cms.getItems('onde_estamos', { fields: 'cidade' })).data

      const adicionaArray = (obj) => {
        this.optionsCidade.push(obj)
      }

      const recebeObj = (objValue) => {
        const obj = {}

        obj.text = objValue
        obj.value = objValue

        return adicionaArray(obj)
      }

      const pegaCidades = () => {
        const arrCidades = []

        this.arrayCidades.forEach(cidade => {
          const limpaArr = cidade.cidade.trim().split(' - ')[0]
          arrCidades.push(limpaArr)
        })

        const filteredArray = arrCidades.filter((i, prox) => arrCidades.indexOf(i) == prox)

        filteredArray.map(selectObj => {
          if (filteredArray.length >= this.optionsCidade.length)
            recebeObj(selectObj)
        })
      }

      if (this.optionsCidade.length === 1)
        pegaCidades()
    },
    
    async submit(e) {
      this.formEnviando = true

      var body = new FormData(e.target)

      var res = await fetch('./form.php', { method: 'post', body })

      if (res.ok)
        this.formEnviado = true
      else {
        this.formEnviando = false

        if (res.status === 400)
          this.formInvalido = true
        else
          this.formErro = true
      }

      this.formEnviando = false
    },

    copyTextCurriculo() {
      this.clipboardText = document.getElementById('emailCurriculo')

      this.clipboardText.select()
      this.clipboardText.setSelectionRange(0, 99999)
      navigator.clipboard.writeText(this.clipboardText.value)
      this.textCopy = 'Copiado!'
    },

    copyTextParcerias() {
      this.clipboardText = document.getElementById('emailParcerias')

      this.clipboardText.select()
      this.clipboardText.setSelectionRange(0, 99999)
      navigator.clipboard.writeText(this.clipboardText.value)
      this.textCopy = 'Copiado!'
    },

    setCopyMessage() {
      this.textCopy = 'Clique para copiar!'
    },

    resetForm() {
      this.form = {
        cidade: 'Cidade',
        assunto: 'assunto',
        nome: '',
        email: '',
        mensagem: '',
      }
      this.formInvalido = false
      this.formErro = false
      this.formEnviado = false
    },
  },
}
</script>
